/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
#main-div{
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 90vh;
    margin-left: 80px;
    padding: 10px;
}
#main-div .heading{
    margin-top: 50px;
    font-size: 40px;
}
.top-bar {
    margin-top: 20px;
    width: 100%;
    display: flex; 
    justify-content: flex-end; 
}

.top-bar .savePdf{
 width: 150px;
 border-radius: 50px;
 align-self: center;
 height: 50px;
 margin-top: 140px;
 margin-right: 40px;
 text-transform: none;
}
.savePdf:hover{
    
    background-color: rgb(89, 89, 250);
    color: white;
}
#main-div .subhead span{
    font-size: 50px;
    font-family: "Poppins", sans-serif;
    color: blue;
}
.subhead{
    font-size: 50px;
    gap: 20px;
}
#SitesComponents{
    width: 100%;
    align-items: center;
    justify-content: space-around;
    display: flex;
}
#SitesComponents  .PieChart{
    margin-top: 10px;
    width: 570px;
    height: 480px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}
.Cards {
    
    display: flex;             /* Enables Flexbox */
    align-items: center;       /* Centers content vertically */
       /* Centers content horizontally */
    justify-self: center;
    width: 308px;
    border-radius: 20px;
    background-color: rgb(226, 226, 226);
    height: 117px;
}
.Cards .Card-Data{
    align-items: center;
    display: flex;
    justify-content: space-around;
}
.Card-Data .fab-button{
    border: 2px solid white;
    margin-left: 20px;
}
.Card-Data .Card-heading{
margin-left: 20px;
font-size: 15px;
width: 150px;
text-align: left;
}

.grids-container{
margin-top: 10px;
margin-left: 80px;
display: grid;
padding: 1px;
grid-gap: 10px;
grid-template-columns: repeat(2,1fr);

}
.barChartPage2{
    background-size: cover;
  background-position: center;
  height: 80vh;
  background-repeat: no-repeat;
  align-items: center;
  width: 100%;
  margin-top: 90px;  
}
.barChart {
  background-size: cover;
  background-position: center;
  height: 80vh;
  background-repeat: no-repeat;
  align-items: center;
  width: 100%;
  margin-top: 90px;
}

.barChart .barchart-data{
    margin-top: 50px;
}
.barChart .left-heading{
    display: flex;
}
.breakdownData{
    align-items: center;
    display:  flex;
}
.vertical-divider {
    border-left: 2px solid rgb(207, 205, 205); /* Adjust the thickness and color */
    height: 400px; /* Adjust the height */
    margin: 0 20px; /* Adjust the spacing around the divider */
}
.barChart .heading2{
    margin-left: 30px;
    width: 200px;
    font-weight: 300;
}
.siteDetected{
    margin-top: 90px;
}
.PolarChart{
    margin-top: 50px;
    width: 570px;
    height: 480px;
    border-radius: 10px;
    align-items: center;
    justify-content: center; 
}
.container{
    align-items: center;
    display: flex;
    width: 100%;
}
.filter{
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.select-zone{
    border-radius: 8px;
    padding: 15px;
    margin-right: 50px;
}
.select-detalis{
    background-color: none;
    color: none;
}
.select-Size{

    margin-right: 0px;
}
.design {

    background-size: cover;        /* Ensures the image covers the container */
    background-position: center;   /* Centers the image within the container */
    background-repeat: no-repeat;  /* Prevents the image from repeating */
    width: 100%;                   /* Full width */
    height: 80vh;                  /* Adjusted height */
  

}
.design-PieChart{
    background-size: cover;        /* Ensures the image covers the container */
    background-position: center;   /* Centers the image within the container */
    background-repeat: no-repeat;  /* Prevents the image from repeating */
                      /* Full width */
  
}
.Doughnutchart{
    height: 300px;
}
.chart3{
    margin-top: 10px;
}

.divider{
    width: 320px;
    height: 2px;
    margin-left: 40px;
    background-color: black;
}
/* Universal styles for consistent scaling */
.design-PieChart, .Doughnutchart, .barChartPage2 {
    width: 100%;
}

.Page2{
    height: 900px;
}
.IncreaseHeight{
    height: 730vh;
}


 /* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  }

 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .design-PieChart{
        height:690px
    }
    .barChartPage2{
        height: 750px;
    }
 }

 /* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .barChartPage2{
        height: 750px;
    }
 }
/* @media (min-width: 1300px) { 
    .barChartPage2{
        height: 670px;
    }
    .design-PieChart{
        height: 890px;
    }
    .chart3{
        height: 680px;
    }
 } */
/* @media (min-width: 1340px) { 
    .barChartPage2{
        height: 680px;
    }
    .design-PieChart{
        height: 610px;
    }
    .chart3{
        height: 680px;
    }
 } */
@media (min-width: 1399px) { 
    .barChartPage2{
        height: 680px;
    }
    .design-PieChart{
        height: 400px;
    }
    .chart3{
        height: 680px;
    }
 }

 /* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .barChartPage2{
        height: 710px;
    }
    .design-PieChart{
        height: 720px;
    }
    .chart3{
        height: 700px;
    }
 }
@media (min-width: 1499px) { 
    .barChartPage2{
        height: 400px;
    }
    .design-PieChart{
        height: 700px;
    }
    .chart3{
        height: 1200px;
    }
 }

@media (min-width: 1500px) { 
    .barChartPage2{
        height: 780px;
    }
    .chart3{
        height: 720px;
    }
 }
@media (min-width: 1600px) { 
    .barChartPage2{
        height: 820px;
    }
    .chart3{
        height: 800px;
    }
 }
@media (min-width: 1700px) { 
    .barChartPage2{
        height: 890px;
    }
    .chart3{
        height: 800px;
    }
 }
@media (min-width: 1800px) { 
    .barChartPage2{
        height: 880px;
    }
    .chart3{
        height: 890px;
    }
    .design-PieChart{
        height: 900px;
    }
 }


/* //////////////// */


.DemoChartPage2{
    width: "100%";
    height: 100px;
}