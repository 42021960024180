.login-form-container {
    height: 100vh;
    width: 100vw;
    background: var(--bg-color);
    position: absolute;
    top: 0;
    left: 0;
}

.login-form-container h1 {
    color: var(--text-color);
}

.login-form-sub-container {
    margin: 20vh auto;
    width: 70vw;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: var(--bg-color);

}

.login-form-sub-container * {
    color: var(--text-color);
}

.login-form-sub-container p {
    margin: 0;
}

.login-form-sub-container button {
    width: 15rem;
    border: 2px solid var(--border-color);
    margin: 2rem auto;
    font-size: 1.5rem;
    padding: 0.5rem 2rem;
    background: var(--secondary-bg-color);
    color: var(--text-color);
    font-weight: bolder;
    border-radius: 0.5rem;
    cursor: pointer;
}

.login-form-sub-container button:hover {
    width: 17rem !important;
    cursor: pointer;
}