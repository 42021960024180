 .marker-popup-container {
  margin: 0;
  padding: 1rem 0rem;
  border-radius: 1rem;
  /* border: 1px solid var(--secondary-border-color); */
  background: var(--bg-color);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  width: 32rem;
} 

.spline-loader {
  width: 100vw;
}

.select-path-traversal-container {
  color: var(--secondary-hyperlink-color);
  font-family: "Poppins";
  font-size: 1rem;
  padding: 0 1rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.select-path-traversal-label {
  display: flex;
  align-items: center;
}

.select-path-traversal-label:hover {
  font-weight: bold;
}

.marker-popup-top-status {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.marker-popup-top-show-more {
  cursor: pointer;
  color: var(--text-color);
}

.marker-popup-top-show-more:hover {
  font-weight: bold;
}

.select-path-traversal {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  width: 1rem;
  height: 1rem;
  border: 2px solid var(--secondary-hyperlink-color);
  border-radius: 50%;
  margin-right: 0.5rem;
}

.select-path-traversal:checked {
  background-color: var(--secondary-hyperlink-color);
}

.marker-popup-middle-container {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  margin-bottom: 0.2rem;
}

.popup-image-sub-container {
  width: 100%;
  padding: 0;
}

.popup-image-container {
  border: 1px solid var(--border-color);
  border-radius: 9px;
  /* height: 11.5rem; */
  /* background: gray; */
  height: 27rem;
  width: 30rem;
}

.date-container {
  display: flex;
  user-select: none;
}

.date-container > p {
  position: absolute;
  bottom: 3.5rem;
  background: var(--bg-color);
  font-weight: 700;
  font-family: "Poppins";
  margin-left: 0.2rem;
  padding: 0.1rem 0.2rem;
  border-radius: 0.5rem;
  font-size: 0.7rem;
  line-height: 1;
}

#image-2-date {
  left: 16rem;
}

.popup-image {
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(255, 255, 255, 0.8);
}

.marker-popup-coordinates-container {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.popup-map-link-container {
  display: flex;
  justify-content: space-evenly;
  border-top: 2px solid #8d8d8d;
  padding-top: 0.4rem;
}

.popup-map-link-container:nth-child(1) {
  border-left: 2px solid red;
}

.popup-map-link-sub-container {
  text-align: center;
  width: 50%;
  /* padding: 1rem; */
  margin-top: 0.5rem;
  padding-bottom: 0;
}

.popup-map-link-sub-container > a {
  color: var(--text-color);
  font-size: 1rem;
  text-decoration: none;
}

.mapboxgl-popup-close-button {
  display: none;
}

.popup-map-link-sub-container {
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
}

.popup-map-link-sub-container:hover {
  font-weight: 800;
}
