@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.coming-soon-banner {
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 30rem;
  width: 30%;
  z-index: 9999;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  animation: slideInFromTop 4s infinite; /* Apply the animation */
}

.banner-text {
  font-size: 2rem;
  color: var(--text-color);
  /* opacity: 0; Hide the text initially */
}

.coming-soon-banner.show {
  animation: slideInFromTop 4s infinite;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
