.geotrics-footer {
  position: absolute;
  bottom: 0.8rem;
  right: 1rem;
  padding: 0.3rem 0.5rem;
  color: var(--text-color);
  border-radius: 0.75rem;
  border: 0.1px solid #fff;
  background: var(--bg-color);
  backdrop-filter: blur(25px);
  flex-shrink: 0;
  display: flex;
  margin: 0;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  user-select: none;
}

.geotrics-footer img {
  width: 1rem;
  margin: 0;
  padding: 0;
}
