.table-container {
  margin: 0 5rem 0 10rem;
  height: 100vh;
  width: 90rem;
  overflow: auto;
  overflow: none;
}

.table {
  font-family: "Poppins";
  width: 100%;
  border-collapse: collapse;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-size: 1.2rem;
}

.table th,
.table td {
  white-space: nowrap;
  border: none;
  padding: 18px 30px;
  text-align: left;
}

.table-row {
  background: var(--bg-color);
  margin: 2rem;
  overflow: hidden;
  color: var(--text-color);
  font-weight: bold;
}

a {
  color: var(--text-color);
  text-decoration: none;
  padding: 0.15rem;
  padding-top: 0.6rem;
}

a:visited {
  color: var(--text-color);
  text-decoration: none;
}

a:hover {
  color: #0054C0;
  /* text-decoration: underline; */
}

select {
  padding: 8px 12px;
  font-size: 1rem;
  border: none;
  background-color: var(--table-select-bg-color);
  color: #333;
  border-radius: 4px;
  width: 15rem;
  /* max-width: 200px; Optional: Limit the maximum width of the select element */
  box-sizing: border-box;
}

/* Optional: Hover and focus styles for the select element */
select:hover,
select:focus {
  /* outline: none;  */
  background-color: #e6e6e6;
}

/* Optional: Styling for the options within the select element */
select option {
  padding: 8px 12px;
}

.row {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.table-sub-container {
  box-sizing: border-box;
  height: 85vh;
  padding-top: 3rem;
  width: 100%;
  color: var(--text-color);
  overflow: none;
}

.filters-section {
  height: 2rem;
  flex-direction: row-reverse;
}

.table-sub-container .MuiTableCell-root.MuiTableCell-head {
  background: #202427 !important;
  color: var(--text-color);
  text-decoration: solid;
  text-transform: uppercase;
}

thead.MuiTableHead-root {
  background: #202427 !important;
}

.table-header {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  /* border: 2px solid var(--border-color); */
}

.data-row-container {
  /* border: 1px solid var(--border-color); */
  /* margin-top: 1rem; */
  height: 75vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 0;
}

.data-row {
  color: var(--text-color);
  border-radius: 0.5rem;
  background: var(--table-row-bg-color);
  padding: 1rem 2rem;
  text-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;
  font-size: 0.875rem;
}

.filters-section {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
}

.filters-left-section {
  display: flex;
  gap: 1rem;
}

.filters-dropdown-label {
  padding: 0;
  margin: 0;
  color: #818181;
  font-size: 0.9rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.filters-button-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.filters-dropdown-selected-label {
  border-radius: 0.5rem;
  border: 1px solid var(--secondary-border-color);
  background: var(--bg-color);
  padding: 0.7rem 1rem;
  width: 10rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.filters-dropdown-selected-label p {
  margin: 0;
}

.filters-dropdown-sub-container {
  padding: 0rem;
}

.zone-filter-dropdown-container {
  width: 14rem !important;
}

.filters-dropdown-sub-container
  .status-select-dropdown
  .site-info-status-select-sub-container {
  padding: 5px 0;
  overflow-y: auto;
}


.search-site-id {
  border: 1px solid var(--border-color);
  padding: 0.65rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--secondary-border-color);
  background: var(--bg-color);
}

.search-site-id input {
  padding: 0;
  color: var(--text-color);
  background: var(--bg-color);
}