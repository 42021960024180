/* styles.css */
:root {
  --primary-bg-color-light: #F2F4F5;
  --secondary-bg-color-light: white;
  --tertiary-bg-color-light: #F2F4F5;
  --primary-text-color-light: #112238;
  --secondary-text-color-light: white;
  --primary-hyperlink-color-light: #112238;
  --secondary-hyperlink-color-light: #0054C0;
  --primary-border-color-light: white;
  --secondary-border-color-light: #112238;
  --table-select-bg-color-light: white;
  --sidebar-switch-track-color-light: gray;
  --modal-bg-color-light: #F2F4F5;
  --button-bg-color-light: #0054C0;
  --button-color-light: white;
  --edit-address-modal-input-bg-color-light: #F2F4F5;
  --edit-address-modal-input-color-light: #112238;
  --master-container-bg-color-light: white;
  --table-row-bg-color-light: #F2F4F5;
  --pencil-icon-color-light: #0054C0;
  --old-status-badge-bg-color-light: #F2F4F5;
  --new-status-badge-bg-color-light: #112238;
  --old-status-badge-text-color-light: #112238;
  --new-status-badge-text-color-light: white;
  --dashboard-button-bg-color-light: white;
  --dashboard-button-bg-color-hover-light: #0054C0;
  --dashboard-button-bg-color-selected-light: #0054C0;
  --bg-modal-blur-color-light: rgba(255, 255, 255, 0.7);

  --primary-bg-color-dark: black;
  --secondary-bg-color-dark: #545454;
  --tertiary-bg-color-dark: #202427;
  --primary-text-color-dark: #ffffff;
  --secondary-text-color-dark: #ffffff;
  --primary-hyperlink-color-dark: #112238;
  --secondary-hyperlink-color-dark: #7EE787;
  --primary-border-color-dark: #4F4F4F;
  --secondary-border-color-dark: white;
  --table-select-bg-color-dark: #F2F4F5;
  --sidebar-switch-track-color-dark: white;
  --modal-bg-color-dark: hsla(0,0%,9%,.9);
  --button-bg-color-dark: black;
  --button-color-dark: #7EE787;
  --edit-address-modal-input-bg-color-dark: #353535;
  --edit-address-modal-input-color-dark: white;
  --master-container-bg-color-dark: #202427;
  --table-row-bg-color-dark: #353535;
  --pencil-icon-color-dark: #7EE787;
  --old-status-badge-bg-color-dark: #353535;
  --new-status-badge-bg-color-dark: white;
  --old-status-badge-text-color-dark: white;
  --new-status-badge-text-color-dark: #112238;
  --dashboard-button-bg-color-dark: black;
  --dashboard-button-bg-color-hover-dark: #353535;
  --dashboard-button-bg-color-selected-dark: #353535;
  --bg-modal-blur-color-dark: rgba(0, 0, 0, 0.7);
}

/* styles.css */
.light {
  --bg-color: var(--primary-bg-color-light);
  --secondary-bg-color: var(--secondary-bg-color-light);
  --tertiary-bg-color: var(--tertiary-bg-color-light);
  --text-color: var(--primary-text-color-light);
  --secondary-text-color: var(--secondary-text-color-light);
  --border-color: var(--primary-border-color-light);
  --secondary-border-color: var(--secondary-border-color-light);
  --table-select-bg-color: var(--table-select-bg-color-light);
  --sidebar-switch-track-color: var(--sidebar-switch-track-color-light);
  --modal-bg-color: var(--modal-bg-color-light);
  --button-bg-color: var(--button-bg-color-light);
  --button-color: var(--button-color-light);
  --edit-address-modal-input-bg-color: var(--edit-address-modal-input-bg-color-light);
  --edit-address-modal-input-color: var(--edit-address-modal-input-color-light);
  --secondary-hyperlink-color: var(--secondary-hyperlink-color-light);
  --master-container-bg-color: var(--master-container-bg-color-light);
  --table-row-bg-color: var(--table-row-bg-color-light);
  --pencil-icon-color: var(--pencil-icon-color-light);
  --old-status-badge-bg-color: var(--old-status-badge-bg-color-light);
  --new-status-badge-bg-color: var(--new-status-badge-bg-color-light);
  --old-status-badge-text-color: var(--old-status-badge-text-color-light);
  --new-status-badge-text-color: var(--new-status-badge-text-color-light);
  --dashboard-button-bg-color: var(--dashboard-button-bg-color-light);
  --dashboard-button-bg-color-hover: var(--dashboard-button-bg-color-hover-light);
  --dashboard-button-bg-color-selected: var(--dashboard-button-bg-color-selected-light);
  --bg-modal-blur-color: var(--bg-modal-blur-color-light);
}

.dark {
  --bg-color: var(--primary-bg-color-dark);
  --secondary-bg-color: var(--secondary-bg-color-dark);
  --tertiary-bg-color: var(--tertiary-bg-color-dark);
  --text-color: var(--primary-text-color-dark);
  --secondary-text-color: var(--secondary-text-color-dark);
  --border-color: var(--primary-border-color-dark);
  --secondary-border-color: var(--secondary-border-color-dark);
  --table-select-bg-color: var(--table-select-bg-color-dark);
  --sidebar-switch-track-color: var(--sidebar-switch-track-color-dark);
  --modal-bg-color: var(--modal-bg-color-dark);
  --button-bg-color: var(--button-bg-color-dark);
  --button-color: var(--button-color-dark);
  --edit-address-modal-input-bg-color: var(--edit-address-modal-input-bg-color-dark);
  --edit-address-modal-input-color: var(--edit-address-modal-input-color-dark);
  --secondary-hyperlink-color: var(--secondary-hyperlink-color-dark);
  --master-container-bg-color: var(--master-container-bg-color-dark);
  --table-row-bg-color: var(--table-row-bg-color-dark);
  --pencil-icon-color: var(--pencil-icon-color-dark);
  --old-status-badge-bg-color: var(--old-status-badge-bg-color-dark);
  --new-status-badge-bg-color: var(--new-status-badge-bg-color-dark);
  --old-status-badge-text-color: var(--old-status-badge-text-color-dark);
  --new-status-badge-text-color: var(--new-status-badge-text-color-dark);
  --dashboard-button-bg-color: var(--dashboard-button-bg-color-dark);
  --dashboard-button-bg-color-hover: var(--dashboard-button-bg-color-hover-dark);
  --dashboard-button-bg-color-selected: var(--dashboard-button-bg-color-selected-dark);
  --bg-modal-blur-color: var(--bg-modal-blur-color-dark);
}
