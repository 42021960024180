.dashboard-container {
  display: flex;
  gap: 2rem;
  overflow: scroll;
}

.dashboard-container > .dashboard-sub-container {
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  width: 42vw;
  height: 85vh;
  box-sizing: border-box;
  margin-bottom: 2rem;
  overflow: hidden;

}

.dashboard-sub-container .map-component-container {
  height: 33vh;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0 1.5rem;
  padding-top: 1.5rem;
}

.dashboard-recent-activities-header {
  height: 4vh;
  padding: 0 1.5rem;
  margin: 0;
}

.dashboard-recent-activities-body {
  color: var(--text-color);
  border-top: 1px solid var(--secondary-border-color);
  overflow: auto;
  /* max-height: 100%; */
  box-sizing: border-box;
  height: 100vh;
  /* height: 100%; */
}

.dashboard-sub-container:last-of-type .dashboard-recent-activities-body {
  /* max-height: 22rem; */
  height: 37vh;
}

.dashboard-recent-activity-type {
  display: flex;
  align-items: center;
}

.dashboard-recent-activity-type span {
  width: 1.5em;
}

.dashboard-recent-activity {
  font-size: 1rem;
  display: flex;
  align-items: top;
  gap: 1rem;
  padding: 1rem 1.5rem;
  margin: 0;
  border-bottom: 1px solid var(--border-color);
}

.dashboard-recent-activity-content-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.dashboard-recent-activity-content-footer {
  font-size: 0.7rem;
  /* color: var(--secondary-text-color); */
  margin-top: 0.2rem;
  display: flex;
  gap: 0.8rem;
}

.site-status-wise-dashboard {
    height: 50%;
    overflow: hidden;
}

.site-status-wise-dashboard-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--secondary-bg-color);
}

.site-status-wise-dashboard-filter-button {
  /* margin-top: 1px; */
  border: 1px solid var(--secondary-border-color);
  /* border-top: none; */
  /* border-bottom: none; */
  background-color: var(--dashboard-button-bg-color);
  padding: 1rem 1rem;
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 900;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  text-transform: uppercase;
}

.site-status-wise-dashboard-filter-button:hover {
  background: var(--dashboard-button-bg-color-hover);
  color: var(--secondary-text-color);
  border: 1px solid var(--secondary-border-color);
}

.site-status-wise-dashboard-filter-button.selected {
  background: var(--dashboard-button-bg-color-selected);
  color: var(--secondary-text-color);
  border: 1px solid var(--secondary-border-color);
  font-weight: 900;
}

/* on first button set top left border radius 1rem */
.site-status-wise-dashboard-filter-button:first-of-type {
  border-top-left-radius: 1rem;
  border-right: none;
}

/* on last button set top right border radius 1rem */
.site-status-wise-dashboard-filter-button:last-of-type {
  border-top-right-radius: 1rem;
  border-left: none;
}

.site-status-wise-dashboard-chart p.filter-label {
  text-transform: capitalize;
}

.site-status-wise-dashboard-chart-header {
    box-sizing: border-box;
    border-top: 1px solid var(--secondary-border-color);
    border-bottom: 1px solid var(--secondary-border-color);
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    align-items: center;
}

.site-status-wise-dashboard-chart-header h3 {
    margin: 0;
    color: var(--text-color);
}

.site-status-wise-dashboard-chart-header h5 {
    margin: 0;
    font-weight: 100;
}

.site-status-wise-dashboard-chart-header h5:hover {
    font-weight: 900;
}

.site-status-wise-dashboard-chart-header .row {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.site-status-wise-dashboard-chart-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
    /* width: 30vw; */
}

.site-status-wise-dashboard-chart-body .MuiChartsLegend-label {
    color: var(--text-color);
    fill: var(--text-color);
}

.site-status-wise-dashboard-chart-header .filters-dropdown-selected-label {
    width: 6rem;
    padding: 0.3rem 0.5rem;
}

.site-status-wise-dashboard-chart-header .status-select-dropdown {
    z-index: 99;
    width: 10rem !important;
}

.badge {
  border: 1px solid var(--secondary-border-color);
  padding: 0 0.5rem;
  border-radius: 0.3rem;
  margin: 0 0.5rem;
}

.badge.old-status {
  background: var(--old-status-badge-bg-color);
  color: var(--old-status-badge-text-color);
}

.badge.new-status {
  background: var(--new-status-badge-bg-color);
  color: var(--new-status-badge-text-color);
}

.gap-1 {
  display: flex;
  gap: 0.2rem !important;
}

