.site-details-sidebar-container {
    color: var(--text-color);
    height: 100vh;
    position: absolute;
    top: 0;
    left: 5rem;
    width: 25rem;

    flex-shrink: 0;
    border-radius: 0px 12px 12px 0px;
    border-top: 1px solid #b1b1b1;
    border-left: 1px solid #b1b1b1;
    background: var(--secondary-bg-color);
    backdrop-filter: blur(25px);

    display: flex; /* Add this property */
    flex-direction: column;
    align-items: center; /* Add this property */
    justify-content: space-between;
    z-index: 99999;
}

.site-details-sidebar-container-collapse {
    width: 2rem;
    padding-top: 2rem;
}

.site-details-sidebar-top-container {
    width: 100%;
    padding: 1rem;
    padding-bottom: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.site-details-sidebar-top-sub-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid grey;
    padding: 1rem;
}

.site-details-sidebar-top-sub-container > * {
    padding: 0 !important;
    margin: 0 !important;
}

.site-details-sidebar-top-sub-filter-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}

.site-details-sidebar-top-sub-filter-container > * {
    color: var(--text-color);
}

.sidebar-filter-checkbox-container {
    display: flex;
    align-items: center;
}

.sidebar-filter-checkbox-container > * {
    border: 1px solid var(--border-color);
    height: 2.5rem;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
}

.sidebar-filter-checkbox-container > div {
    height: 2.4rem;
    border-left: 0;
    cursor: pointer;
}

.search-locations-container {
    width: 100%;
    background: var(--bg-color);
    border-radius: 5px;
    border-color: var(--border-color);
    text-align: center;
}

.enter-email-container {
    margin: 0 auto;
    width: 92%;
}

.search-locations-container.enter-email-container div {
    padding-left: 0;
}

#outlined-search-locations {
    background: var(--bg-color);
    height: 2rem;
    padding: 0.4rem 0.5rem;
    border-radius: 5px;
    color: var(--text-color);
    text-align: center;
}

#enter-email-list {
    background: var(--bg-color);
    height: 2rem;
    padding: 0.4rem 0.5rem;
    border-radius: 5px;
    color: var(--text-color);
    border: 1px solid var(--secondary-border-color);
    text-align: center;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
}

.site-details-sidebar-bottom-container {
    padding: 1rem 0;
    width: 100%;
    height: 100%;
}

.input-box-number-of-people {
    margin-top: 2rem;
    text-align: center;
}

.site-details-sidebar-bottom-container > h4 {
    margin: 0;
    padding: 0 1rem;
    padding-bottom: 1rem;
}

.selected-sites-container {
    overflow: auto;
    height: 65vh;
    padding: 1rem 0;
}

.individual-site-container {
    display: flex;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
    font-size: 0.95rem;
    border-bottom: 1px solid var(--secondary-border-color);
}

.individual-site-container p {
    font-size: 0.8rem;
}

.site-details-box * {
    text-transform: capitalize;
    padding: 0;
    margin: 0;
    padding-bottom: 0.2rem;
    text-wrap: balance;
}

.site-details-sidebar-footer {
    position: absolute;
    bottom: 2rem;
}

.site-details-sidebar-footer button.create-path-traversal {
    margin: 0 auto;
    width: 18rem;
    background: transparent;
    color: var(--button-color) !important;
}

.link-box-container {
    box-sizing: border-box;
    border-radius: 5px;
    width: 80%;
    margin: 2rem auto;
    text-align: center;
    max-height: 70vh;
    overflow-y: auto;
}

.link-box-container > p {
    font-size: 0.9rem;
    margin-bottom: 2rem;
}

.link-box-container > .link-boxes {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0;
    max-height: 60vh;
    overflow: auto;
    border: 1px solid;
}

.link-boxes > div {
    border-radius: 6px;
    border-bottom: 1px solid var(--secondary-border-color);
    background: var(--bg-color);
    padding-bottom: 0;
}

#real-link-box {
    font-size: 13px;
    padding: 1rem 3rem;
    word-wrap: break-word;
}
#copy-path-traversal-link-btn {
  padding: 0.5rem 0.5rem;
}

#copy-path-traversal-link-btn:hover {
  cursor: pointer;
  border-bottom: 1px solid #575757;
  background: var(--bg-color);
}

.error-message-box {
    color: red;
    font-size: 12px;
    width: 80%;
    margin-bottom: 2rem;
}

.error-message-box-2 {
    padding: 1rem;
    margin: 0 auto;
}

.border-red {
    border: 2px solid red;
}

.filters-container {
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    height: 3.5rem;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    background: rgb(256, 256, 256, 0.4);
}

.filter-dropdown {
    position: absolute;
    right: 0.5rem;
    top: 7.5rem;
    height: max-content;
    padding: 0.4rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background: var(--bg-color);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 99999;
}

.filter-dropdown .site-info-status-select-sub-container {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 0.1rem;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    border: 0;
    overflow: none;
    margin-top: 0;
}

.filter-dropdown-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.search-field-members {
    width: 100%;
}

.MuiInputBase-adornedStart {
    display: flex;
    gap: 1rem;
    color: var(--text-color) !important;
    font-weight: 100;
}

.middle-container-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.middle-container-header h5 {
    cursor: pointer;
    font-weight: 100;
}

.middle-container-header h5:hover {
  font-weight: bold;
}
/* Container for each link and worker name */
.link-container {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Alternating background colors */
.light-background {
  background-color: #f9f9f9;
}

.dark-background {
  background-color: #e9e9e9;
}

/* Link text styling */
.link-text {
  color: #333;
}

/* Worker name and copy icon styling */
.worker-name-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers items horizontally */
  gap: 5px;
}

/* Worker name styling with larger font size */
.worker-name {
  font-weight: bold;
  color: #555;
  font-size: 1rem; /* Increase font size */
}


/* Copy icon styling */
.copy-icon {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
}

/* Tooltip styling */
.tooltip-text {
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Show tooltip on hover */
.copy-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

