.upload-image-btn-container {
  position: absolute;
  bottom: 3.8rem;
  right: 1rem;
  padding: 2.5rem;
  color: var(--text-color);
  border-radius: 10px;
  border: 0.1px solid var(--text-color);
  background: var(--bg-color);
  backdrop-filter: blur(25px);
  flex-shrink: 0;
  display: block;
  margin: 0;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  user-select: none;
}

.upload-image-btn-container:hover {
  background: var(--tertiary-bg-color);
  border: 1.1px solid var(--text-color);
}

.geotrics-footer img {
  width: 1rem;
  margin: 0;
  padding: 0;
}

.circle-border {
  border-radius: 10%;
  border: 0.1px solid var(--text-color);
  padding: 0.4rem;
  cursor: pointer;
}

/* on hover */
.circle-border:hover {
  background: var(--tertiary-bg-color);
  border: 0.1px solid var(--text-color);
}

.fa-2x {
  font-size: 2.5rem;
}