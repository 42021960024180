.vertical-sidebar-main-container {
  position: fixed;
  top: 0;
  left: 5rem;
  height: 100vh;
  width: 25rem;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: first baseline;
  padding: 1rem 1rem !important;
  box-sizing: border-box;
  border: 1px solid var(--border-color);
}

.dark .vertical-sidebar-main-container {
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  border-top: 1px solid #b1b1b1;
  border-left: 1px solid #b1b1b1;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(25px);
}

.light .vertical-sidebar-main-container {
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  border-top: 1px solid #b1b1b1;
  border-left: 1px solid #b1b1b1;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);
}

.vertical-sidebar-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.vertical-sidebar-footer {
  position: absolute;
  bottom: 1rem;
  background: var(--button-bg-color);
  color: var(--button-color);
  padding: 1rem;
  width: 83%;
  border-radius: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

/* .vertical-sidebar-footer:hover {
  background: var(--button-hover-bg-color);
  color: var(--button-hover-color);
} */

.vertical-sidebar-body-box .select-label-selected-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 20rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  text-transform: capitalize;
  background: var(--bg-color);
}

.vertical-sidebar-main-container .status-select-dropdown {
  text-transform: capitalize;
  height: fit-content;
  z-index: 1000;
}

.vertical-sidebar-main-container .status-select-dropdown .site-info-status-select-sub-container {
  padding: 0rem 0.5rem;
}

.vertical-sidebar-main-container #enter-email-list {
  width: 100%;
  position: relative;
  left: -0.7rem;
  text-align: left;
  border: none;
  padding: 0.2rem 0.5rem;
}