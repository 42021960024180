body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  background: var(--bg-color) !important;
}



.sidebar-container {
  background-color: var(--bg-color);
  color: var(--text-color);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  display: flex; /* Add this property */
  flex-direction: column;
  align-items: center; /* Add this property */
  justify-content: space-between;
  z-index: 1000000;
  overflow: hidden;
}

.sidebar-container {
  width: 5rem;
  transition: all 0.2s ease;
}

.sidebar-container:hover {
  width: 16rem;
  background: var(--bg-color);
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
}

.sidebar-sub-container {
  display: flex; /* Add this property */
  flex-direction: column;
  align-items: center; /* Add this property */
  justify-content: center;
}

.sidebar-container:hover .sidebar-sub-container {
  align-items: baseline;
  width: 100%;
}

.sidebar-sub-container-top {
  padding-top: 1.5rem;
}

.sidebar-container-row {
  margin: 1.5rem;
  overflow: visible;
  cursor: pointer;
}

.sidebar-row-container {
  display: flex;
  align-items: center;
  /* font-weight: bolder; */
  cursor: pointer;
}

.sidebar-container:hover .sidebar-row-container:hover {
  /* font-size: 1.05rem;  */
  font-weight: bold;
}

.sidebar-row-container p {
  display: none;
}

.sidebar-container:hover .sidebar-row-container p {
  display: block;
  transition: width 0.2s ease;
}

.sidebar-row-container-logo {
  height: 5rem;
  overflow: hidden;
}

.sidebar-logo {
  width: 2.5rem;
  transition: width 0.2s ease;
  overflow: hidden;
}

.full-width-logo {
  display: none;
}

.sidebar-container:hover .sidebar-logo {
  width: 12rem;
}

.sidebar-container:hover .collapsed-logo {
  display: none;
}

.sidebar-container:hover .full-width-logo {
  display: block;
}

.sidebar-container .MuiSwitch-track {
  background-color: var(--sidebar-switch-track-color);
  opacity: 0.8;
  width: 30rem !important;
}