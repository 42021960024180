.topbar-container {
  color: var(--text-color);
  height: 15vh;
  width: 93vw;
  position: fixed;
  top: 0;
  right: 0;
  display: flex; /* Add this property */
  align-items: center; /* Add this property */
  justify-content: space-between;
  padding-left: 2rem;
  /* z-index: 9999; */
}

.topbar-sub-container {
  display: flex; /* Add this property */
  align-items: center; /* Add this property */
  justify-content: space-between;
  gap: 1rem;
}

.topbar-sub-container-right {
  padding-right: 2rem;
}

.box-container.box-create-path-traversal {
  background: var(--button-bg-color);
}

.box-container {
  display: flex;
  align-items: center;
  background: var(--bg-color);
  padding: 20px 15px;
  height: 2vh;
  border-radius: 12px;
  color: var(--text-color);
  width: 12rem;
}

.box-search {
  width: 6rem;
}

.box-select {
  padding: 20px 0;
  width: 10rem;
}

.box-create-path-traversal {
  border: 2px solid var(--border-color);
  width: fit-content;
  margin: 0 auto;
}

button.create-path-traversal {
  color: #0054C0 !important;
  font-weight: bold;
  padding: 20px 15px !important;
  margin: 0;
}

.select-box {
  color: var(--text-color) !important;
}

.container-icon {
  margin-right: 1rem;
}

#input-with-sx {
  color: var(--text-color);
  width: 18rem;
}


.filters-button {
  padding: 1rem;
  width: 19rem;
}

.filters-button-p {
  margin: 0;
  flex-grow: 1;
  width: 100%;
  text-align: left;
  font-size: 0.75rem;
  font-weight: bold;
}

.filters-dropdown {
  position: absolute;
  height: 40rem;
  border: 2px solid #0054C0;
}

.filters-dropdown-container-box .status-select-dropdown {
  width: 19rem;
  min-width: fit-content;
}

.filters-dropdown-container-box .status-select-dropdown .site-info-status-select-sub-container {
  padding: 0rem 1rem;
  color: var(--text-color);
}

.filters-dropdown-container-box .status-select-dropdown .MuiButtonBase-root .MuiCheckbox-root {
  padding: 0;
}


.site-info-status-select-sub-container .MuiCheckbox-root {
  padding: 10px;
  color: var(--text-color) !important;
}

.site-info-status-select-sub-container .MuiCheckbox-root .PrivateSwitchBase-input {
  padding: 0;
}