.dashboard-main-container {
  color: var(--text-color);
  padding-left: 10rem;
  padding-top: 1rem;
  height: 100vh;
  width: 100vw;
  overflow: none;
}

.language-form-container {
  display: flex;
  gap: 2rem;
}

.language-form-sub-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.language-form-sub-container textarea {
  width: 30vw;
  height: 10rem;
  font-size: 1.5rem;
  font-family: Verdana, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.dashboard-main-container button {
  width: 15rem;
  height: 5rem;
  margin-top: 3rem;
  font-size: 2rem;
}