.dotted-circle-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 6px dotted var(--secondary-border-color);
  opacity: 0.6;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.full-page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  background: transparent;
  top: 0;
  left: 0;
}