.upload-image-box-container {
  position: absolute;
  left: 5rem;
  z-index: 99999;
  
  display: grid;
  grid-template-rows: auto 1fr auto; /* Header, middle, footer */
  grid-template-columns: 1fr; /* Single column */
  grid-gap: 0.5rem; /* Adjust spacing as needed */
  
  width: 25rem;
  height: 100vh;
  
  padding: 1rem 2rem;
  padding-top: 3rem;
  
  transition: border 0.3s ease;
  
  color: var(--text-color);
  background-color: var(--secondary-bg-color);
  border-color: var(--border-color);
  backdrop-filter: blur(25px);

  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;

  box-sizing: border-box;
  overflow: hidden;
}

/* First child acting as header */
.upload-image-box-container > :first-child {
  grid-row: 1;
  grid-column: 1;
}

/* Last child acting as footer */
.upload-image-box-container > :last-child {
  grid-row: 3;
  grid-column: 1;

  /* center all child elements */
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  gap: 1rem;
}

/* Middle child spanning full width */
.upload-image-box-container > :nth-child(2) {
  grid-row: 2;
  grid-column: 1;
}

.upload-image-box-container > :nth-child(3) {
  grid-row: 3;
  grid-column: 1;
}

.upload-image-box-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.upload-image-box-container .header h4 {
  padding: 0;
  margin: 0;
}

.upload-image-box-container .site-info-details-sub-container h5 {
  width: 40%;
}

.upload-image-box-container .site-info-details-sub-container p {
  /* padding: 0.2rem 0.5rem; */
  width: 100%;
}

.upload-image-box-container .site-info-details-sub-container * {
  font-size: 1rem;
}


.upload-image-box {
  box-sizing: border-box;
  border: 2px dashed var(--secondary-border-color);
  border-radius: 1.5rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  padding: 1rem;
  max-height: 89%;
  max-width: 21rem;
  height: 100%;
  cursor: pointer;
  overflow: auto;

  transition: max-height 1s ease;
}

.upload-image-box.has-images {
  max-height: 15vh;
  transition: max-height 1s ease;
}

/* Styles for the dragging effect */
.upload-image-box.dragging {
  border-color: var(--secondary-border-color);
}

.upload-message {
  text-align: center;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.upload-message button {
  background-color: var(--button-bg-color);
  color: var(--button-color);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.upload-message button:hover {
  opacity: 0.8;
}

.upload-message p:last-child {
  margin-top: 10px;
}

.upload-image-preview {
  box-sizing: border-box;
  max-height: 58vh;
  height: 100%;
  overflow: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.upload-image-preview::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.img-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem;
  border: 1px solid var(--secondary-border-color);
  border-radius: 0.5rem;
  overflow: visible;
}

.img-box .delete-btn {
  padding: 0.2rem 0.3rem;
  font-size: 1rem;
  background: transparent;
  color: var(--text-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding-top: 0.3rem;
  padding-bottom: 0rem;
}

.img-box .delete-btn * {
  font-size: 0.9rem;
}

.img-box img {
  max-width: 100%;
  border-radius: 0.5rem;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.img-box p {
  padding: 0.5rem;
  margin: 0;
  padding-bottom: 0rem;
}

#upload-toserver-btn {
  background-color: var(--button-bg-color);
  color: var(--button-color);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.close-btn {
  background-color: transparent;
  border: none;
  color: var(--text-color);
  cursor: pointer;
}

.upload-image-box-container .footer .have-images {
  display: flex;
  justify-content: space-between !important;
}

.upload-more-btn {
  color: var(--button-color);
  border: none;
  padding: 0.4rem 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  background: var(--secondary-bg-color);
  border: 1px dashed;
}

