.custom-map-popup {
    margin: 0;
    padding: 0;
    max-width: 40rem !important;
    background: transparent !important;
}

.clusterView{
  justify-content: center;
  align-items: center;
  display: flex;
}
.MapViews{
  margin-top: 10px;
}

.custom-map-popup > .mapboxgl-popup-content {
    /* margin: 0; */
    padding: 0;
    background: var(--bg-color);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(25px);
    width: 25rem;
    max-height: 30rem;
    color: var(--text-color);
    border-radius: 1rem;
}

.custom-map-popup > .mapboxgl-popup-tip {
    border-right-color: var(--bg-color);
}

@keyframes colorChange {
    0% {
      color: red;  /* Starting color */
    }
    25% {
      color: yellow;  /* Transition to yellow */
    }
    50% {
      color: green;  /* Transition to green */
    }
    75% {
      color: blue;  /* Transition to blue */
    }
    100% {
      color: red;  /* Back to red */
    }
  }
  
