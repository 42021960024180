.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: black;
}

.loading-container-heading {
  color: var(--text-color);
  z-index: 1000000;
  position: relative;
  top: 22rem;
  font-size: 2.7rem;
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  display: flex;
  align-items: baseline;
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: var(--secondary-bg-color);
  animation: dot-animation 1.2s infinite;
}

@keyframes dot-animation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    transform: translateX(20px);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(40px);
  }
}
