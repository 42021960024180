.add-modal {
  height: max-content;
  padding: 2rem;
}

.add-modal-cell-container {
  display: flex;
}

.add-modal-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
