.site-info-sidebar-container {
  color: var(--text-color);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 5rem;
  width: 25rem;

  flex-shrink: 0;
  border-radius: 0px 12px 12px 0px;
  border-top: 2px solid var(--border-color);
  border-left: 2px solid var(--border-color);
  background: var(--secondary-bg-color);
  backdrop-filter: blur(25px);

  display: flex; /* Add this property */
  flex-direction: column;

  transition: border 1.3s ease;

  font-size: 1rem;
}

.site-info-sidebar-container .gap-1 {
  gap: 0.5rem !important;
}

.site-info-sidebar-container .badge {
  border: 1px dashed var(--secondary-border-color);
  border-radius: 0.5rem;
  background: var(--secondary-bg-color);
  padding-right: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  padding: 0.1rem 0.3rem;
  margin: 0;
}

.site-info-sidebar-container .badge:hover {
  font-weight: 100;
  cursor: pointer;
  background: var(--secondary-border-color);
  color: var(--secondary-bg-color);
}

.site-info-sidebar-header {
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #aeaeae;

  padding: 1rem;
}

.site-info-sidebar-header * {
  margin: 0;
  padding: 0;
}

.siteid-container {
  padding-top: 1rem;
  display: flex;
  /* gap: 1rem; */
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.siteid-container a:hover {
  background: var(--secondary-border-color);
  color: var(--secondary-bg-color);
  padding: 0.15rem;
  padding-top: 0.6rem;
  border-radius: 50%;
  font-weight: bold;
  transition: background 0.3s ease;
}

.m-0 {
  margin: 0;
}

.site-info-status-select-container {
  padding: 1rem;
  border-bottom: 1px solid #aeaeae;
}

.site-info-status-select-sub-container {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  border: 2.5rem;
  overflow: none;
  margin-top: 0.5rem;
  background-color: grey;
}

.hide {
  display: none;
}

.status-select-dropdown {
  position: absolute;
  width: 22.5rem;
  min-width: fit-content;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: var(--secondary-bg-color);
  box-shadow: 9px 11px 11px 4px rgba(0, 0, 0, 0.6);
  max-height: 60vh;
  overflow: auto;
}

.status-select-dropdown .site-info-status-select-sub-container {
  margin: 0;
}

.status-select-dropdown .site-info-status-select-sub-container {
  padding: 15px 1.5rem;
  border-bottom: 1px solid #aeaeae;
}

.status-select-dropdown .site-info-status-select-sub-container .check-color {
  color: var(--text-color) !important;
}

.status-select-dropdown .site-info-status-select-sub-container:hover {
  font-weight: 900;
  /* font-size: 1.1rem; */
}

.status-select-dropdown .site-info-status-select-sub-container:last-child {
  border-bottom: none;
}

.site-info-details-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  overflow: auto;
}

.site-info-details-container * {
  font-size: 1rem;
}

.site-info-details-sub-container {
  margin: 0.6rem 0;
}

.site-info-details-sub-container p {
  margin: 0.3rem 0;
  text-wrap: balance;
  font-size: 0.9rem;
}

.site-info-details-sub-container h4 {
  display: flex;
  flex-direction: row;
  margin: 0.3rem 0;
  text-wrap: balance;
  font-size: 1rem;
  justify-content: space-between;
}

.edit-address-modal-container {
  /* background: rgb(256,256,256,0.3); */
  height: 100vh;
  width: 75rem;
  position: absolute;
  left: 25rem;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editable-true {
  cursor: pointer;
  padding: 0.5rem;
  border-bottom: 1px solid var(--text-color);
  font-weight: 500;
  border-radius: 0.5rem;
}

.modal-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999;

  /* center div */
  display: flex;
  align-items: center;
  justify-content: center;

  /* blur background */
  background: var(--bg-modal-blur-color);
  backdrop-filter: blur(25px);
}

.modal-container .modal-container-box {
  width: fit-content;
  min-width: 40vw;
  max-width: 60vw;
  min-height: auto;
  max-height: 45rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  padding: 1rem;
  padding-bottom: 2rem;
  background: var(--modal-bg-color);
  border: 1px solid var(--secondary-border-color);
}

.modal-container .modal-container-box .topbar {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.modal-container .modal-container-box .topbar h3 {
  width: 100%;
  text-align: center;
}

.modal-container .site-info-ground-inspection-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  overflow: hidden;
}

.modal-container .site-info-ground-inspection-image-container img {
  width: 30vw;
  object-fit: contain;
  max-height: 40rem;

  /* box shadow */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.6);
}

.image-box-meta-container {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 3rem;
  padding-top: 0;
}

.metadata-value {
  margin-left: 0.4rem;
  border-bottom: 1px solid var(--secondary-border-color);
  padding: 0.1rem 0.4rem;
  border-radius: 0.3rem;
  background: var(--bg-modal-blur-color);
}

.modal-container .modal-container-box .image-box-container-with-controls {
  width: 100%;
}

.modal-container .modal-container-box .image-box-container-with-controls .control-btn {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container .img-pagination {
  padding: 10px;
  gap: 1rem;
}

.modal-container .img-pagination .pagination-circles {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--bg-color);
  border: 1px solid var(--text-color);
}

.modal-container .img-pagination .pagination-circles.fill {
  background: var(--text-color);
}

.edit-address-modal {
  width: 45.1875rem;
  height: 35rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 1px solid var(--secondary-border-color);
  background: var(--modal-bg-color);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  /* backdrop-filter: blur(135px); */
  padding: 1rem 2rem;
  box-sizing: border-box;
}

.edit-address-modal-header {
  display: flex;
}

.edit-address-modal-header h3 {
  flex-grow: 1;
  margin: 0;
  text-align: center;
}

.close-modal {
  position: relative;
  right: 0;
}

.edit-address-form {
  display: flex;
  flex-direction: column;
}

.edit-address-form-cell-container {
  display: flex;
  flex-direction: column;
  padding: 0.7rem 0;
}

.edit-address-form-cell-container .edit-address-input {
  border-radius: 0.5rem;
  border: 1px solid var(--secondary-border-color);
  background: var(--edit-address-modal-input-bg-color);
  color: var(--edit-address-modal-input-color);
}

.edit-address-form-cell-container .edit-address-input * {
  color: var(--edit-address-modal-input-color);
}

.edit-address-state-pincode-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.edit-address-state-pincode-container .edit-address-form-cell-container {
  width: 100%;
}

.save-address-button {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.save-address-button button {
  border-radius: 0.25rem;
  background: var(--button-bg-color);
  display: flex;
  width: 11.4375rem;
  height: 2.5rem;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  flex-shrink: 0;
  color: var(--button-color);
  font-size: 0.875rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
}

.fa span.cursor-pointer {
  width: 1.5em !important;
}

span.cursor-pointer {
  width: 1.5em !important;
}

.site-info-ground-inspection-images-sub-container {
  padding: 1rem 0rem;
  padding-bottom: 0;
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-direction: row;
  gap: 1rem;
}

.site-info-ground-inspection-images-sub-container img {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
  border-radius: 0.5rem;
  border: 1px solid var(--primary-border-color);
}

.disabled {
  color: #aeaeae;
}